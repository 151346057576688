import PropTypes from 'prop-types';
import { Box, Card, IconButton, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../services/api.service';

const apiService = new ApiService();

export const FormsTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0
  } = props;

  const navigate = useNavigate();

  const editForm = (formId: number) => {
    navigate(`/form/${formId}`);
  };

  const deleteForm = async (id: number) => {
    Swal.fire({
      title: "Are you sure you want to remove this form?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        apiService.delete("/forms/" + id).then(response => {
          if (response?.status == 200) {
            window.location.reload();
          }
        });
      }
    });
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
              
  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Description
              </TableCell>
              <TableCell>
                Created At
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((form: any) => {
              return (
                <TableRow
                  hover
                  key={form.id}
                >
                  <TableCell>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >                        
                      <Typography variant="subtitle2">
                        {form.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {form.description}
                  </TableCell>
                  <TableCell>
                    {form.createdAt}
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <EditIcon onClick={() => editForm(form.id)} />
                    </IconButton>
                    <IconButton onClick={() => deleteForm(form.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

FormsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
};