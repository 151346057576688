import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { ApiService } from "../../services/api.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiService = new ApiService();

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (event: any) => {
    const value = event.target.value;
    
    setData({
      ...data,
      [event.target.name]: value
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const userData = {
      email: data.email,
      password: data.password
    };

    try {
      const response = await axios.post("https://api.secondchancefinancials.com/login", userData);
    
      if (response.data.message == 'User not found') {
        notify(response.data.message);

        setData({
          email: "",
          password: ""
        })

        navigate("/");
        return;
      }

      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("role", response.data.role);

      const roles = await apiService.get("roles");
      
      const userRoles = roles?.data;
      localStorage.setItem("roles", JSON.stringify(userRoles));

      navigate("dashboard");
    } catch (error) {
      console.error('ERROR: ' + JSON.stringify(error));
    }
  };

  const notify = (message: string) => toast(message);

  return (      
    <div className="login-page">
      <img src="/images/logo-login.png" className=""/>
      <div className="form">
        <form className="login-form" onSubmit={handleSubmit}>
          <input type="text" placeholder="Email" name="email" value={data.email} onChange={handleChange} />
          <input type="password" placeholder="Password" name="password" value={data.password} onChange={handleChange} />
          <button>login</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
  
export default Login;