import { useEffect, useState } from 'react';
import { Box, Button, Container, createTheme, Input, Modal, Stack, TextareaAutosize, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormsTable } from '../../sections/forms';
import Sidenav from '../../components/Sidenav';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../services/api.service';

const apiService = new ApiService();

const Forms = () => {
  const navigate = useNavigate();
  
  const now = new Date();

  const [data, setData] = useState([]);
  const [updateForms, setUpdateForms] = useState<number | undefined>(0);
  
  const [form, setForm] = useState({
    name: "",
    description: ""
  });

  const handleChange = (event: any) => {
    const value = event.target.value;
    
    setForm({
      ...form,
      [event.target.name]: value
    });
  };

  const fetchData = async () => {
    try {
      const response = await apiService.get("/forms");
      
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createForm = async () => {
    try {
      setUpdateForms(0);

      const response = await apiService.post("/forms", form);
      setUpdateForms(response?.status);
      
      setForm({
        name:"",
        description: ""
      });

      handleClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [updateForms]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10, 
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Forms
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                </Stack>
              </Stack>
              <div>
                <Button
                  onClick={() => {navigate("/form-builder")}}
                  startIcon={<AddIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#003D6E',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#005F8C',
                    },
                  }}
                >
                  Add
                </Button>
              </div>
            </Stack>
            <FormsTable
              count={data.length}
              items={data}
            />
          </Stack>
        </Container>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Form
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input type="text" placeholder="Name" name="name" value={form.name} onChange={handleChange} slotProps={{ input: { className: 'textarea' } }} />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <TextareaAutosize placeholder="Description" name="description" value={form.description} onChange={handleChange} minRows="4" />
          </Typography>
          <Button color="primary" variant="outlined" onClick={handleClose} sx={{ mt: 2, marginRight: 1 }}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={createForm} sx={{ mt: 2 }}>Save</Button>
        </Box>
      </Modal>
    </>
  );
};

Forms.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default Forms;