import { Link } from 'react-router-dom';
import './style.css';

const Register = () => {
    return (      
      <div className="login-page">
        <div className="form">
          <form className="register-form">
            <input type="text" placeholder="Name"/>
            <input type="password" placeholder="Password"/>
            <input type="text" placeholder="Email"/>
            <button>create</button>
            <p className="message">Already registered? <Link to="/">Sign In</Link></p>
          </form>
        </div>
      </div>
    );
  }
  
  export default Register;
