import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Sidenav from "../../components/Sidenav";
import { ApiService } from "../../services/api.service";

const apiService = new ApiService();

interface ApplicationData {
  formName: string;
  sections: Section[];
}

interface Section {
  uuid: string;
  name: string;
  isHidden: boolean;
  questions: Question[];
}

interface Question {
  id: number;
  name: string;
  question: string;
  questionType: string;
  isRequired: boolean;
  hasConditionalSection: boolean;
  answers: Answer[];
  userAnswers: UserAnswer[];
}

interface Answer {
  answer: string;
  associatedSection: string;
}

interface UserAnswer {
  id: number;
  answer: string;
}

const Application = () => {
  const { applicationId } = useParams<{applicationId: string}>();

  const [application, setApplication] = useState<ApplicationData | null>(null);
  const [visibleSections, setVisibleSections] = useState<{[key: string]: boolean}>({});
  
  const navigate = useNavigate();

  const getApplication = async () => {
    try {
      const response = await apiService.get('/applications/' + applicationId);
      setApplication(response?.data);

      const visibility: {[key: string]: boolean} = {};

      response?.data.sections.forEach((section: Section) => {
        visibility[section.uuid] = !section.isHidden;
      });
      
      setVisibleSections(visibility);
    } catch (error) {
      console.error("Error getting application data:", error);
    }
  }

  useEffect(() => {
    getApplication();
  }, [applicationId]);

  const handleInputChange = (sectionUuid: string, questionId: number, value: string) => {
    if (!application) {
      return;
    }

    const updatedApplication = {
      ...application,
      sections: application.sections.map(section =>
        section.uuid === sectionUuid
        ? {
          ...section,
          questions: section.questions.map(question =>
            question.id === questionId
            ? {
              ...question,
              userAnswers: [
                ...question.userAnswers.map((answer, index) =>
                  index === 0
                    ? { ...answer, answer: value }
                    : answer
                )
              ]
            }
            : question
          )
        }
        : section
      )
    };

    setApplication(updatedApplication);
};


  const handleAnswerChange = (sectionUuid: string, questionId: number, answer: string) => {
    if (!application) return;

    const updatedApplication = { ...application };
    const section = updatedApplication.sections.find(s => s.uuid === sectionUuid);
    
    if (section) {
      const question = section.questions.find(q => q.id === questionId);
      
      if (question) {
        question.userAnswers = [{ id: 0, answer }];
      }
    }

    const newVisibleSections = { ...visibleSections };
    
    if (section) {
      section.questions.forEach(question => {
        question.answers.forEach(ans => {
          if (ans.associatedSection) {
            newVisibleSections[ans.associatedSection] = (ans.answer === answer);
          }
        });
      });
    }

    setApplication(updatedApplication);
    setVisibleSections(newVisibleSections);
  };

  const saveAnswers = async () => {
    const body = {
      userAnswers: application?.sections.flatMap(section =>
        section.questions.map(question => ({
          questionId: question.id,
          userAnswers: question.userAnswers
        }))
      )
    };

    console.log(JSON.stringify(body));

    try {
      await apiService.patch("/applications/" + applicationId, body).then((data) => {
        if (data) {
          navigate("/applications");
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

  const style = {
    borderLeft: '4px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const formHeaderStyle = {
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const boxStyle = {
    display: 'flex',
  }

  const alignLeftStyle = {
    flex: '1'
  }

  const textAreaStyle = {
    width: '70%',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    borderRadius: '3px'
  }

  const titleStyle = {
    width: '70%',
    marginBottom: '25px'
  }

  const inputStyle = {
    width: '70%',
    marginBottom: '15px'
  }

  const inputTitleStyle = {
    width: '70%',
    marginBottom: '45px'
  }

  const labelStyle = {
    marginLeft: '5px',
    marginBottom: '5px'
  }

  const optionsStyle = {
    marginBottom: '15px'
  }
 
  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">          
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">
                Application
              </Typography>
              <Stack 
                alignItems="center" 
                direction="row" 
                spacing={1}
              >                
              </Stack>
            </Stack>
            <div>
              <Button 
                onClick={() => {navigate("/applications")}} 
                startIcon={<ClearIcon />} 
                variant="contained"
                sx={{
                  mt: 2, 
                  marginBottom: '15px',
                  backgroundColor: '#003D6E',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#005F8C',
                  },
                }}
              >
                Cancel
              </Button>
            </div>
          </Stack>
          {
            application?.sections.map((section, index) => (visibleSections[section.uuid] && 
              <div key={index}>
                <Stack style={formHeaderStyle}>
                  <Typography style={titleStyle}>{section.name}</Typography>
                  <Stack>
                    {
                      section.questions.map((question, questionIndex) => {
                        if (question.name != "") {
                          return (
                            <div style={style}>
                              <div style={boxStyle}>
                                <div key={question.name} style={alignLeftStyle}></div>                                  
                              </div>
                              <div>
                                <Typography style={inputStyle}>{question.question}</Typography>
                                {
                                  question.questionType == "shortAnswer" && 
                                  <>                                    
                                    {
                                      question.userAnswers.length > 0 ? 
                                        <TextField 
                                          id="outlined-basic" 
                                          variant="outlined" 
                                          style={inputStyle} 
                                          value={question.userAnswers[0].answer} 
                                          onChange={(e) => handleInputChange(section.uuid, question.id, e.target.value)}
                                        /> 
                                      : 
                                        <TextField id="outlined-basic" variant="outlined" style={inputStyle} />
                                    }
                                    
                                  </>
                                }
                                {
                                  question.questionType == "paragraph" && 
                                  <TextareaAutosize 
                                    style={textAreaStyle} 
                                    minRows={5} 
                                    placeholder={question.question} 
                                    onChange={(e) => handleInputChange(section.uuid, question.id, e.target.value)}
                                  />
                                }
                                {
                                  question.questionType == "singleChoice" && 
                                  <div>
                                    <div style={optionsStyle}>
                                      {
                                        question.answers.map((answer) => 
                                          <div style={boxStyle}>
                                            <div style={alignLeftStyle}>
                                              {
                                                question.userAnswers[0].answer == answer.answer ?
                                                  <input 
                                                    type="radio" 
                                                    name={`answer-${questionIndex}`} 
                                                    key={`answer-${questionIndex}`} 
                                                    value={answer.answer} 
                                                    checked={question.userAnswers[0]?.answer === answer.answer} 
                                                    onChange={() => handleAnswerChange(section.uuid, question.id, answer.answer)} 
                                                  />
                                                :
                                                  <input 
                                                    type="radio" 
                                                    name={`answer-${questionIndex}`} 
                                                    key={`answer-${questionIndex}`} 
                                                    value={answer.answer} 
                                                    checked={question.userAnswers[0]?.answer === answer.answer} 
                                                    onChange={() => handleAnswerChange(section.uuid, question.id, answer.answer)} 
                                                  />
                                              }
                                              <label style={labelStyle}>{answer.answer}</label>
                                            </div>
                                          </div>
                                      )}
                                    </div>
                                  </div>
                                }
                                {
                                  question.questionType == "multipleChoice" && 
                                  <div>
                                    <div style={optionsStyle}>
                                      {
                                        question.answers.map((answer) => 
                                          <div>
                                            <input 
                                              type="checkbox" 
                                              name={question.name} 
                                              key={answer.answer} 
                                              value={answer.answer} 
                                            />
                                            <label style={labelStyle}>{answer.answer}</label>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </div>
                                }
                                {
                                  question.questionType == "file" && 
                                  <div>
                                    <TextField type="file" />
                                  </div>
                                }
                              </div>
                            </div>
                          );
                        }
                      })
                    }
                  </Stack>
                </Stack>
              </div>
            ))
          }
          <Button 
            onClick={() => saveAnswers()} 
            startIcon={<DoneIcon />} 
            variant="contained" 
            sx={{
              mt: 2, 
              marginTop: '15px',
              marginLeft: '10px', 
              backgroundColor: '#003D6E',
              color: 'white',
              '&:hover': {
                backgroundColor: '#005F8C',
              },
            }}
          >
            Save
          </Button>          
        </Container>
      </Box>
    </>
  );
}

export default Application;