import React from 'react';
import { Box, Container, Divider, Paper, Stack, styled, Typography } from '@mui/material';
import Sidenav from '../../components/Sidenav';
import { BarChart, useDrawingArea, PieChart } from '@mui/x-charts';

const Dashboard = () => {
  const data = [
    { value: 5, label: 'A' },
    { value: 10, label: 'B' },
    { value: 15, label: 'C' },
    { value: 20, label: 'D' },
  ];
  
  const size = {
    width: 400,
    height: 200,
  };
  
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const chartSetting = {
    width: 500,
    height: 400,
  };
  
  const valueFormatter = (value: number | null) => `${value}mm`;

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Typography 
              variant="h5"
              sx={{
                color: '#003D6E'
              }}
            >
              Dashboard
            </Typography>
            <Divider
              sx={{ 
                bgcolor: '#003D6E', 
                paddingBottom: '5px'
              }}
            >              
            </Divider>
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              sx={{ 
                paddingTop: '20px'
              }}
            >
              <Paper 
                sx={{ 
                  p: 2, 
                  flex: 1, 
                  bgcolor: 'white', 
                  height: '500px'
                }}
              >
                Opportunity Status
                <br /><br />
                <Divider
                  sx={{ 
                    bgcolor: '#F1F1F1',
                  }}
                >              
                </Divider><br /><br /><br /><br />
                <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
                  <PieCenterLabel>Center label</PieCenterLabel>
                </PieChart>
              </Paper>
              <Paper 
                sx={{ 
                  p: 2, 
                  flex: 1, 
                  bgcolor: 'white', 
                  height: '500px',
                  marginRight: '20px' 
                }}
              >
                Opportunity Value
                <br /><br />
                <Divider
                  sx={{ 
                    bgcolor: '#F1F1F1',
                  }}
                >              
                </Divider>
                <BarChart
                  series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                  
                  layout="horizontal"
                  {...chartSetting}
                />
              </Paper>
              <Paper 
                sx={{ 
                  p: 2, 
                  flex: 1, 
                  bgcolor: 'white', 
                  height: '500px',
                  marginRight: '20px' 
                }}
              >
                Conversion Rate
                <br /><br />
                <Divider
                  sx={{ 
                    bgcolor: '#F1F1F1',
                  }}
                >              
                </Divider><br /><br /><br /><br />
                <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
                  <PieCenterLabel>Center label</PieCenterLabel>
                </PieChart>
              </Paper>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;